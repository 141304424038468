import React from 'react'
import { graphql } from 'gatsby'

import Section from '../components/cms/Section'
import PropTypes from 'prop-types'

import { RepairBlogSectionRenderer } from '../section-renderer/repair-blog'

const RepairBlogPostPage = ({ data }) => {
  return <RepairBlogSectionRenderer data={data} />
}
RepairBlogPostPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      brand: PropTypes.shape({
        brand: PropTypes.string,
        urlPath: PropTypes.string,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
      url: PropTypes.string,
      safeHeroImage: PropTypes.object,
      table_of_contents: PropTypes.bool,
      seo: PropTypes.object,
      model: PropTypes.string,
      featuredService: PropTypes.string,
      sections: PropTypes.arrayOf(Section.propTypes.section),
      bannerText: PropTypes.string,
    }),
  }),
}

export const query = graphql`
  query RepairBlogPostQuery($pageId: Int!) {
    page: strapiRepairBlogPost(strapi_id: { eq: $pageId }) {
      safeHeroImage {
        gatsbyImageDataMock
      }
      brand {
        brand
        urlPath
        services {
          label: title
          url
        }
      }
      model
      url: urlPath
      table_of_contents
      createdAt
      publishedAt
      updatedAt
      bannerText
      featuredService
      seo {
        ...SEO
      }
      sections {
        ... on STRAPI__COMPONENT_SECTION_YOUTUBE_VIDEOS {
          ...YoutubeSection
        }
        ... on STRAPI__COMPONENT_SECTION_IMAGE_SECTION {
          ...ImageSection
        }
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
          ...ParagraphSection
        }
      }
    }
  }
`

export default RepairBlogPostPage
